.menuP{
    display: block;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.465);
    height: 90vh;
    padding: 4px;
    .menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        a{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 50px;
            padding: 10px;
            gap: 10px;
            border-radius: 10px;
            background: white;
            color: black;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.465);
            text-decoration: none;
            &:hover{
                background: rgba(0, 0, 255, 0.138);
                color: blue;
                border-radius: 5px;
                padding: 0 10px;
                box-sizing: border-box; 
            
        }
        // button{
        //     padding: 10px;
        //     border: 0;
        //     color: #000;
        //     border-radius: 4px ;
        //     background-color: #f5f7f8;
        //     font-size: 1.2em;
        //     margin: 0 auto;
        //     font-weight: 700;
        //     text-transform: uppercase;
        //     &:hover{
        //         background-color: #000;
        //         color: #fff;
        //     }

        //     spaN{
        //         display: none;
        //     }
        } 
        .active{
            background-color: #000;
            color: #fff;
        }
    }
}