.admin-Product {
    display: flex;
    padding: 11px;
}

#form__add {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .backdrop {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    form {
        position: relative;
        overflow: auto;
        width: 400px;
        padding: 20px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px #000;
        display: flex;
        flex-direction: column;
        button{
            width: 100px;
            padding: 2px;
            border: 0;
            color: #fff;
            border-radius: 4px;
            background-color: #000;
            cursor: pointer;
            &:hover {
                background-color: rgb(54, 41, 188);
                color: rgb(217, 216, 255);
                transform: rotateZ(11deg) scale(1.1);
                transition: all 0.3s;
                z-index: 999;
    
            }
        }

        input {
            height: 22px;
            border-radius: 4px;
            border: 1px solid #000;
            border: 0;
            padding: 0 5px;
            width: 359px;
            box-shadow: 0 0 124px blue, 0 0 0px blue inset;
        }

        textarea {
            height: 55px;
            border-radius: 4px;
            border: 1px solid #000;
            padding: 0 10px;
            width: 359px;
            border: 0;
            padding: 0 10px;
            box-shadow: 0 0 124px blue, 0 0 119px #0000ff38 inset;
        }

        .imm {
            background: #f27b7b74;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 22px;
            box-shadow: 0 0 124px blue, 0 0 119px #0000ff38 ;
            #im {
                display: flex;
                align-content: center;
                justify-content: center;
                gap: 10px;
                position: relative;
                border: 2px dashed #000;
                margin: 4px;
                img {
                    width: 55px;
                    height: 55px;
                    object-fit: cover;
                    border-radius: 4px;
                }
    
                input {
                    width: 100%;
                    box-shadow: 0 0 103px blue, 0 0 32px blue inset;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
    
    
                }
            }
        }

        .num {
            display: flex;
            justify-content: center;
            text-align: center;

            input {
                width: 55px;
            }
        }


    }
    
    .modifier {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 2rem;
            font-weight: bold;
        }

            .modifier__item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .price {
                    width: 54px;
                }

                .name {
                    width: 204px;
                }

                div {
                    width: 100%;
                    margin: 2px;
                    color: #000;
                    background: #ffa1a1;
                    border-radius:  0 10px 10px 0;
                    cursor: pointer;
        
                    &:hover {
                        background-color: #ff3d3d;
                    }
                }
        }

        .addmod {
            background: #ffa1a1;
            color: #000000;
            border: none;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            cursor: pointer;

            &:hover {
                background-color: #ff3d3d;
            }
        }
    }
}

.edit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        padding: 12px;
        border: 0;
        color: #fff;
        border-radius: 4px;
        background-color: #000;
        cursor: pointer;

        &:hover {
            background-color: rgb(54, 41, 188);
            color: rgb(217, 216, 255);
            transform: rotateZ(11deg) scale(1.6);
            transition: all 0.3s;
            z-index: 999;

        }
    }
}

.del {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    .body {
        margin: 0 auto;

        .backdrop {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .del__card {
            width: 400px;
            padding: 20px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 0 10px #000;
            margin: 0 auto;

            h3 {
                margin-bottom: 20px;
            }

            button {
                padding: 12px;
                border: 0;
                color: #fff;
                border-radius: 4px;
                background-color: #000;
            }
        }
    }
}

.admin-Product {
    .listProddct {
        margin: 10px;
    }

    display: flex;
    padding: 11px;
    background: #DDD;

    .add {
        width: 222px;
        display: flex;
        padding: 11px;
        margin-bottom: 22px;
        background-color: #fff;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        &:hover {
            // make make background color blur
            background-color: rgba(0, 0, 0, 0.252);

        }
    }
}


.dashboard-setting {
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
}

.dashboard-setting h2 {
    margin: 0;
}

.dashboard-setting .setting-info {
    margin-top: 10px;
}

.dashboard-setting .setting-info p {
    margin: 0;
}