.kitchentap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .table {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        width: 221px;
        cursor: pointer;
        background-color: #f2f2f2;
        padding: 2px;
        border-radius: 10px;
        box-shadow: 2px 2px 5px #888888;

        &:hover {
            background-color: #ccc;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            background: #ffc107;
            color: #000;
            border-radius: 5px;
            font-size: 12px;
            cursor: pointer;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191;

            &:hover {
                background-color: #ccc;
            }

        }

        .titem {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1px;
            width: 221px;
            position: relative;
            cursor: pointer;
            background-color: #f2f2f2;
            padding: 2px;
            border-radius: 10px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

            &:hover {
                background-color: #ccc;
            }

            .qry {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 10px;
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;
                background: rgba(231, 218, 255, 0.852);
                color: rgb(71, 1, 221);
                padding: 1px;
                border-radius: 5px;
                font-size: 12px;

            }

            .note {
                font-size: 10px;
                color: rgb(71, 1, 221);
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

            }

            .modifier {
                display: flex;
                flex-wrap: wrap;
                padding: 1px;
                border-radius: 5px;
                background: rgba(255, 255, 255, 0.5);
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 18px 6px rgb(16 16 16 / 5%);
                gap: 0 5px;

                &:hover {
                    background: rgba(103, 240, 181, 0.252);
                }

                .modifier__item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    background: #d84c4c73;
                    color: #000;
                    border-radius: 5px;
                    padding: 0 5px;
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 18px 6px rgb(16 16 16 / 5%);

                    .price {
                        font-size: 16px;
                        font-weight: 600;
                        background: #dff2326c;
                        border-radius: 11px;
                        padding: 0 5px;
                    }

                    &:hover {
                        background: #f08585;
                    }
                }
            }

        }
    }


}



.guset {

    .catg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 4px;
        box-shadow: 0 0 11px;

        >p {
            img {
                width: 88px;
                height: 88px;
                border-radius: 10%;
                box-shadow: 0 0 10px;
            }

            display: flex;
            flex-direction: column;
            font-size: 12px;
            font-weight: 700;
            padding: 6px;
            border-radius: 22px 11px / 211px 22px;
            color: #fff;
            cursor: pointer;
            background: #000;
            position: relative;

            span {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: rgba(0, 0, 0, 0.359);
                color: #fff;
            }
        }
    }

    background: #fff;
    // background: filter: blur(5px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 12px;
    box-shadow: 0 0 111px black;
    border-radius: 21px;

    .table {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        width: 221px;
        cursor: pointer;
        background-color: #f2f2f2;
        padding: 2px;
        border-radius: 10px;
        box-shadow: 2px 2px 5px #888888;

        &:hover {
            background-color: #ccc;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            background: #ffc107;
            color: #000;
            border-radius: 5px;
            font-size: 12px;
            cursor: pointer;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191;

            &:hover {
                background-color: #ccc;
            }

        }

        .titem {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0px;
            width: 221px;
            position: relative;
            cursor: pointer;
            background-color: #f2f2f2;
            padding: 2px;
            border-radius: 10px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

            &:hover {
                background-color: #ccc;
            }

            .qry {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 10px;
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;
                background: rgba(231, 218, 255, 0.852);
                color: rgb(71, 1, 221);
                padding: 1px;
                border-radius: 5px;
                font-size: 12px;

            }

            .note {
                font-size: 10px;
                color: rgb(71, 1, 221);
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

            }
        }
    }



}

.tablecart {
    cursor: pointer;
    position: fixed;
    bottom: 88px;
    right: 111px;
    z-index: 111;
    left: 55px;

    // hover 
    &:hover {

        .cartN {
            animation: pulse 1s infinite;

            @keyframes pulse {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 24px;
        width: 66px;
        height: 66px;
        // box-shadow: 0 0 17px, 0 0 27px black, 0 0 27px #493a74, 0 0 27px #6f15e8, 0 0 27px #380101, 0 0 27px #5a24e2;

        &:hover {
            animation: pulse 1s infinite;

            @keyframes pulse {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.2);
                }

                100% {
                    transform: scale(1);
                }
            }

        }
    }

    .cartN {
        position: absolute;
        top: 21px;
        left: 32px;
        background: #6459d2;
        color: #ffffff;
        box-shadow: 0 0 17px, 0 0 27px black, 0 0 27px #493a74, 0 0 27px #6f15e8, 0 0 27px #380101, 0 0 27px #5a24e2;
        font-size: 17px;
        padding: 0px;
        border-radius: 8px;
        font-weight: bolder;
    }

}


.table1 {

    padding: 5px 10px;
    border-radius: 8px;
    border: 0;
    font-size: 24px;
    font-weight: 600;
    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
    background: rgba(226, 218, 255, 0.852);
}

.tables1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 26px;
    box-shadow: -35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset 8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);

    .table {
        background-color: #f2f2f2;
        display: flex;
        width: auto;
        cursor: pointer;
        margin: 3px;
        padding: 2px;
        border-top: #005d30 2px solid;
        border-right: #005d30 2px solid;
        border-left: #005d30 2px solid;
        box-shadow: 0 0.5rem 1.25rem 0 rgba(39, 48, 54, 0.2);

        &:hover {
            background-color: #ccc;
        }

        .number {

            background: #005d303a;
            font-weight: bold;
            font-size: 20px;
            border-radius: 50%;
        }

        .tname {
            font-size: 18px;
        }

        ~.Available {
            color: green;
            background: rgba(0, 128, 0, 0.253);
        }

        ~.Busy {
            color: #fff;
            background: #ff1d58;
        }

        ~.Reserved {
            color: blue;
            background: rgba(0, 0, 255, 0.253);
        }

        .btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;

            &.btn-success {
                background-color: green;
                color: #fff;
            }

            &.btn-danger {
                background-color: red;
                color: #fff;
            }

            &:hover {
                background-color: #ccc;
            }
        }
    }
}



.guset {
    display: flex;


    .cart {
        cursor: pointer;
        position: fixed;
        bottom: 88px;
        right: 111px;
        z-index: 111;
        left: 0;

        // hover 
        &:hover {

            .cartN {
                animation: pulse 1s infinite;

                @keyframes pulse {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.2);
                    }

                    100% {
                        transform: scale(1);
                    }
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            border-radius: 24px;
            width: 66px;
            height: 66px;
            // box-shadow: 0 0 17px, 0 0 27px black, 0 0 27px #493a74, 0 0 27px #6f15e8, 0 0 27px #380101, 0 0 27px #5a24e2;

            &:hover {
                animation: pulse 1s infinite;

                @keyframes pulse {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.2);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

            }
        }

        .cartN {
            position: absolute;
            top: 21px;
            left: 32px;
            background: #6459d2;
            color: #ffffff;
            box-shadow: 0 0 17px, 0 0 27px black, 0 0 27px #493a74, 0 0 27px #6f15e8, 0 0 27px #380101, 0 0 27px #5a24e2;
            font-size: 17px;
            padding: 0px;
            border-radius: 8px;
            font-weight: bolder;
        }

    }

    .casher__header {
        width: auto;
        max-width: 405px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.238);
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        background: #fff;
        z-index: 100;




        >button {
            width: 100%;
            background: white;
            color: black;
            border: 0;
            border-radius: 5px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);


            .item {

                display: flex;
                justify-content: space-between;
                align-items: center;

                .invoice__total {
                    font-size: 20px;
                    font-weight: 600;

                    span {
                        padding: 0 10px;
                    }
                }
            }

        }

        .cartbxs {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            direction: rtl;

            .cartbx {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                flex-grow: 1;
                background: white;
                padding: 1px;
                border-radius: 10px;
                position: relative;
                transition: all 0.3s ease-in-out;
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

                .modifier {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                    left: 0;
                    padding: 25px 0 0 0; 
                    border-radius: 5px;
                    background: rgba(255, 255, 255, 0.5);
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 18px 6px rgb(16 16 16 / 5%);
                    font-size: 12px;
                    font-weight: 600;
                    color: #000;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    width: 100%;
                    background: #f085852a;
                    display: none;

                    &:hover {
                        background: rgba(103, 240, 181, 0.252);
                    }

                    .modifier__item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        padding: 5px; 
                        color: #fff;
                        background: #ea3323;
                        border-radius: 5px;

                        .price {
                            font-size: 16px;
                            font-weight: 600;
                            color: #000;
                            background: #fff;
                            border-radius: 11px;
                            padding: 0 5px;
                        }

                        &:hover {
                            background: #f08585;
                        }
                    }
                }

                input,
                .qry {
                    display: flex;
                }

                
                

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 5px;
                }

                .name {
                    display: flex;
                    flex-wrap: wrap;
                }


                .quantity {
                    background: rgba(231, 218, 255, 0.852);
                    color: rgb(71, 1, 221);
                    padding: 1px;
                    border-radius: 5px;
                    font-size: 12px;
                }

                .price {
                    background: rgba(255, 218, 218, 0.852);
                    color: red;
                    padding: 1px;
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: bolder;
                }

                .total {
                    background: #fdd000;
                }

                .cx {
                    .info {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;

                        .info__item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                            font-size: 12px;
                            color: #000;
                            background: #fff;
                            padding: 1px;
                            border-radius: 5px;
                            margin: 0 5px;
                        }
                    }

                    input {
                        left: 0;
                        width: 100%;
                        height: 14px;
                        margin: 0;
                        border: 0;
                        border-radius: 5px;
                        padding: 5px;
                        box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;

                        &:focus {
                            outline: none;
                        }

                    }


                }


            }

        }
    }

    .casher__left {
        position: relative;
        height: 98vh;
        background-color: #ddd;
        padding: 5px;
        box-sizing: border-box;
        overflow-y: scroll;

        .btn-remove,
        .discountByCashier button {
            right: 8px;
            color: red;
            padding: 5px 10px;
            border-radius: 8px;
            border: 0;
            font-size: 24px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
            background: rgba(255, 218, 218, 0.852);
        }

        .invoice__pay {
            position: relative;
            color: blue;
            padding: 5px 10px;
            border-radius: 8px;
            border: 0;
            font-size: 24px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
            // background: rgba(0, 0, 255, 0.852);
            background: rgba(231, 228, 255, 0.852);

            .total {
                position: absolute;
                top: 0;
                left: 0;
                background: red;
                color: rgb(251, 251, 251);
                padding: 1px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 900;
                text-decoration: line-through;
            }
        }

        .cuscash {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

        }

        select {
            border-radius: 5px;
            padding: 5px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;

            &:focus {
                outline: none;
            }

            option {
                background: #fff;
            }
        }

        .type_radio {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .mark {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: rgba(0, 128, 0, 0.098);

                input {
                    display: none;

                    &:checked+label {
                        background: rgba(172, 255, 47, 0.322);
                        border-left: 8px solid green;
                        box-shadow: 0 0 5px green, 0 0 110px green, 0 0 15px green inset;
                    }
                }

                label {
                    border-radius: 10px;
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
                    font-size: 14px;
                    cursor: pointer;
                    background: #9f6b6b;
                    padding: 5px 10px;
                    border-radius: 5px;

                    &:hover {
                        background: rgba(255, 166, 0, 0.102);
                    }
                }

                cursor: pointer;
            }
        }

        .customer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;


            .list {
                width: 100%;
                z-index: 22;

                input {
                    width: 110px;
                    height: 25px;
                    border: 0;
                    border-radius: 5px;
                    padding: 0 10px;
                    box-sizing: border-box;
                }

                .drop {
                    cursor: pointer;
                    position: relative;

                    h3 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
                        border-radius: 5px;
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        background: #fff;
                    }

                    .drop__content {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                    }

                    .drop__item {
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        width: 100%;
                        background: #fff;
                        box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
                        border-bottom: 1px solid #ddd;
                        cursor: pointer;

                        p {
                            background: rgb(255, 167, 119);
                            padding: 5px;
                            border-radius: 5px;
                        }

                        .number {
                            background: #f77;
                            padding: 5px;
                            border-radius: 5px;
                        }

                        &:hover {
                            background: #eee;
                        }
                    }
                }
            }


            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 25px;
                padding: 4px;
                background: #F77;
                border-radius: 5px;
                color: black;
                font-size: 16px;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background: #eee;
                }
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border: 1px solid #e0e0e0;

            thead {
                tr {
                    th {
                        border-bottom: 1px solid #e0e0e0;
                        text-align: left;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border-bottom: 1px solid #e0e0e0;
                        text-align: left;
                    }
                }
            }
        }

        input {
            width: 100%;
            height: 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 0 10px;
            box-sizing: border-box;

            &:focus {
                outline: none;
                border: 1px solid #f77;
            }
        }

        .qry {

            &:hover .keyword-toutch {
                display: flex;
            }

            span {
                margin: 0;
                background: #ff0000;
                color: #fff;
                padding: 0 5px;
                border-radius: 5px;
                font-size: 22px;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .btn {
            background-color: rgba(120, 244, 54, 0.2549019608);
            color: #005d30;
            border: none;
            border-radius: 12px;
            padding: 0 5px;
            text-decoration: none;
            display: inline-block;
            font-size: 28px;
            font-weight: 400;
            margin: 0px 4px;
            cursor: pointer;
        }

        .btn-danger {
            background-color: #f4433641;
            color: #f44336;
        }

        .btn-success {
            background-color: #78f43641;
            color: #005d30;
        }

        .btn-warning {
            background-color: #ff980041;
            color: #7d4b00;
        }

        .invoice {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 4px;
            padding: 10px;
            background: rgb(255, 255, 255);
            width: 100%;
            border-radius: 14px;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            margin: 10px auto;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

            .item {

                display: flex;
                justify-content: space-between;
                align-items: center;

                .invoice__total {
                    font-size: 20px;
                    font-weight: 600;

                    span {
                        padding: 0 10px;
                    }
                }
            }

        }


    }

    .casher__right {
        flex: 1 1;
        overflow: hidden;
        margin-bottom: 125px;

        .cards-cashier {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 7px;
            background: #c7cbe4;


            .card {
                position: relative;
                width: 100px;
                border-radius: 4px;
                font-weight: bolder;
                cursor: pointer;

                &.active {
                    border: 1px solid #ff7429;
                    box-shadow: 0 0 100px red inset;
                }

                &:hover {
                    background: #ff7429;
                }

                .card__price {
                    background: #ff7429;
                    color: black;
                    padding: 0 5px;
                    border-radius: 0 0 0 5px;
                    font-size: 12px;
                }

                img {
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 1px 55px rgb(0, 3, 90);
                    border-radius: 10px;
                    width: 100px;
                }

                .modifier {
                    background: #f3a7a7b8;
                    border-radius: 5px;
                    border-radius: 22px;

                    .modifier__item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        background: #c78888;
                        border-bottom: 1px solid #e0e0e0;
                        font-size: 12px;
                        padding: 0 5px;
                        border-radius: 4px;
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

                        &.active {
                            border: 1px solid #ff7429;
                            box-shadow: 0 0 100px red inset;
                        }


                        .price {
                            font-size: 16px;
                            font-weight: 600;
                            background: #dff2326c;
                            border-radius: 11px;
                            padding: 0 5px;
                        }

                        // hover 
                        &:hover {
                            background: #baf3a7b8;
                        }
                    }
                }
            }

        }

        @media (max-width: 1768px) {

            .cards-cashier {
                direction: rtl;

                font-size: 12px;

                .card {
                    width: 100%;
                    margin: 1px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    border-radius: 15px;
                    box-shadow: 0;
                    font-size: 12px;
                    background: #fff;
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;

                    img {
                        width: 40px;
                        height: 40px;
                    }

                    .card__price {
                        order: 1;
                        width: 33px;

                        .price {
                            font-size: 12px;
                            display: flex;
                            flex-flow: column;
                        }
                    }

                    .card__name {
                        flex: 1;
                        // background: whitesmoke;
                        border-radius: 11px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .modifier {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                        font-size: 12px;
                        border-radius: 15px;
                        box-shadow: 8px 50px 135px 0 green;
                        background: none;
                        font-weight: normal;

                        // &:hover {
                        //     width: 100%;
                        // }
                        .modifier__item {
                            position: relative;
                            top: 100px;
                            border-radius: 10px;
                            padding: 5px;
                            margin: 5px 0;
                            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
                            display: none;
                        }
                    }

                    &:hover .modifier__item {
                        top: 0;
                        width: auto;
                        color: #000;
                        border-radius: 5px;
                        // display: flex;
                    }


                    .card__btn_qry {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        background: #fff;
                        border-radius: 15px;
                        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);

                        .btn-plus {
                            padding: 10px;
                            width: auto;
                            color: #fff;
                            border-radius: 50%;
                            background: #43ae3e;

                            // hover
                            &:hover {
                                background: #a3b65e;
                            }

                            // active
                            &:active {
                                background: #a3b65e;
                                transform: scale(0.9);
                            }
                        }

                        .btn-minus {
                            padding: 10px;
                            border-radius: 50%;
                            color: #fff;
                            background: #ea3323;

                            // hover
                            &:hover {
                                background: #ff7429;
                            }

                            // active
                            &:active {
                                background: #ff7429;
                                transform: scale(0.9);
                            }
                        }
                    }
                }
            }
        }


        @media (max-width: 568px) {
            .card {

                &:hover {
                    flex-wrap: wrap;
                }
            }

            .sidebar {
                display: none;
            }
        }

        // end media query

    }
}

.cardmodel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000b8;
    z-index: 9999;

    .headermodal {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 777px;
        padding: 0 10px;
        height: 50px;
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        .title {
            font-size: 20px;
            font-weight: 600;
        }

        .close {
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            padding: 10px;
            border-radius: 50%;
            background: #ff0000;
            color: #fff;
            margin-right: 10px;

            // hover
            &:hover {
                background: #ff1919;
            }
        }


    }

    .cardmo {
        direction: rtl;
        width: 100%;
        max-width: 777px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        background: #fff;
        border-radius: 25px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        .card__image {
            width: 200px;
            height: 200px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        }

        .card__name {
            font-size: 20px;
            font-weight: 600;
        }

        .card__price {
            font-size: 20px;
            font-weight: 600;
        }

        .card__btn_qry {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            background: none;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
            width: 196px;
            margin: auto;
            border-radius: 27px;
        
            .btn-plus {
                padding: 15px;
                width: auto;
                color: #fff;
                border-radius: 50%;
                background: #43ae3e;

                // hover
                &:hover {
                    background: #a3b65e;
                }

                // active
                &:active {
                    background: #a3b65e;
                    transform: scale(0.9);
                }
            }

            .btn-minus {
                padding: 15px;
                border-radius: 50%;
                color: #fff;
                background: #ea3323;

                // hover
                &:hover {
                    background: #ff7429;
                }

                // active
                &:active {
                    background: #ff7429;
                    transform: scale(0.9);
                }
            }
        }

        .modifier {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .modifier__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // background: #c78888;
                border-bottom: 1px solid #e0e0e0;
                font-size: 12px;
                padding: 5px;
                cursor: pointer;
                border-radius: 10px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                width: auto;
                min-width: 100px;

                &.active {
                    border: 1px solid #ff7429;
                    box-shadow: 0 0 100px red inset;
                }


                .price {
                    font-size: 16px;
                    font-weight: 600;
                    background: #dff2326c;
                    border-radius: 11px;
                    padding: 0 5px;
                }

                // hover 
                &:hover {
                    background: #baf3a7b8;
                }
            }
        }
    }



}


.keyword-toutch {
    display: none;
    position: absolute;
    bottom: -230px;
    right: 0;
    background: #000;
    z-index: 999;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #ddd;
    width: 200px;

    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%),
        1px 25px 35px rgb(16 16 16 / 50%), 1px 30px 60px rgb(16 16 16 / 50%);

    button {
        width: 54px;
        height: 54px;
        margin: 2px;
        background: rgb(4, 141, 148);
        border: none;
        border-radius: 50%;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background: rgb(160, 165, 0);
        }

    }
}



.backarrow {
    background: #f77;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.card__btn_qry {
    transition: all 0.3s ease-in-out;
    background: burlywood;
    color: #000;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;

    .btn-plus {
        background: rgba(255, 119, 119, 0.478);
        color: #000;
        width: 100%;
    }

    .qty {
        color: #111;
        font-size: 17px;
        font-weight: 900;
    }

    .btn-minus {
        background: rgba(207, 255, 119, 0.398);
        border-radius: 5px;
    }

    // haver 
    &:hover {
        color: #000;
        border-radius: 5px;

        .btn-plus {
            background: rgba(255, 119, 119, 0.478);
            padding: 5px 10px;
        }

        .qty {
            color: #111;
            font-size: 17px;
            font-weight: 900;
        }

        .btn-minus {
            background: rgba(207, 255, 119, 0.637);
            color: #000;
            padding: 5px 10px;
        }
    }

}





.main-food {
    width: 100vw;
    justify-content: center;
    align-items: center;
    font-family: "Futura PT", sans-serif;
    color: white;

    background-color: #c7cbe4;
    overflow-x: hidden;

    .info {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 2%;
        justify-content: space-between;

        h2 {
            text-transform: uppercase;
            margin: 0;
            opacity: 0.9;
        }

        .req {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 14px;
            font-weight: 600;
            color: #000;
            text-transform: uppercase;
            background: #fff;
            padding: 5px 10px;
            border-radius: 10px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

            .tb {
                background: #484fb2;
                color: #fff;
                padding: 5px;
                border-radius: 5px;
            }

            .total {
                background: #02bb49;
                color: #fff;
                padding: 5px;
                border-radius: 5px;
            }

            .tax {
                background: #c7b6198d;
                color: #000;
                padding: 5px;
                border-radius: 5px;
            }
        }

    }

    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        text-transform: uppercase;
        flex: 1;
        max-width: 50%;
        margin-left: 10%;

        .nav-item,
        button {
            margin-left: 10px;

            a {
                font-weight: 600;
                color: white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    button {
        background: linear-gradient(180deg, #ea585f 0%, #f96b7b 100%);
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        color: white;
        cursor: pointer;
        box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);
        transition: transform 0.2s ease-in-out;
        text-transform: uppercase;

        &:hover {
            transform: scale(1.1);
        }
    }

    .container {
        position: relative;
        box-sizing: border-box;
        max-height: 100%;
        margin-bottom: 50px;

        .top {
            position: relative;
            margin-bottom: -12%;
            width: 103%;
            z-index: 2;
            pointer-events: none;

            .header {
                width: 100%;
                pointer-events: none;
            }
        }

        .content {
            position: relative;
            background: #ffc7a3;
            background: linear-gradient(90deg, #d57589 0%, #ffc7a3 100%);
            border-radius: min(5vw, 50px);
            padding: 5%;
            padding-top: 10%;
            padding-bottom: 22px;
            left: 1.7%;
            width: 94.7%;
            box-sizing: border-box;

            &::after {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: #4b0632;
                width: 100%;
                height: 100%;
                transform: translate(-10%, 10%);
                filter: blur(40px);
                mix-blend-mode: overlay;
                z-index: -1;
            }
        }

        .grid {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            justify-content: center;

        }

        .item-holder {
            position: relative;
            /* width: 90px; */
            font-weight: bolder;
            padding: 8px;


            .item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 20px 5px 15px 15px;
                box-shadow: -5px 8px 10px rgba(0, 0, 0, 0.2);
                z-index: 1;
                padding: 5px;
                transition: transform 0.2s ease-in-out;
                border: 4px red dotted;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                    background: #fff;
                }

                &:active {
                    transform: scale(0.9);
                }

                img {
                    display: none;
                }

                .label {
                    color: #000;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            .item-holder::after,
            .item::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px 5px 15px 15px;
                box-shadow: -5px 8px 10px rgba(0, 0, 0, 0.2);
                z-index: -1;
                transition: transform 0.2s ease-in-out;
            }

            .item-holder:hover::after,
            .item:hover::after {
                transform: scale(1.1);
            }

            .item-holder:nth-child(1) .item {
                background-color: #f9d9d9;
                background-image: linear-gradient(90deg, #f9d9d9 0%, #f9d9d9 100%);
            }

            .item-holder:nth-child(2) .item {
                background-color: #f9d9d9;
                background-image: linear-gradient(90deg, #f9d9d9 0%, #f9d9d9 100%);
            }

            .item-holder:nth-child(3) .item {
                background-color: #f9d9d9;
                background-image: linear-gradient(90deg, #f9d9d9 0%, #f9d9d9 100%);
            }

            .item-holder:nth-child(4) .item {
                background-color: #f9d9d9;
                background-image: linear-gradient(90deg, #f9d9d9 0%, #f9d9d9 100%);
            }

            .item-holder:nth-child(5) .item {
                background-color: #f9d9d9;
                background-image: linear-gradient(90deg, #f9d9d9 0%, #f9d9d9 100%);
            }

            .item-holder:nth-child(1) .item {
                background-color: #fdf9e2;
                background-image: linear-gradient(90deg, #ecd6d8 0%, #f9e2d2 100%);
            }

            .item-holder:nth-child(2) .item,
            .item-holder:nth-child(7) .item {
                background-color: #e4b3fa;
                background-image: linear-gradient(90deg, #bf92f1 0%, #e4b3fa 100%);
            }

            .item-holder:nth-child(3) .item {
                background-color: #9fffd6;
                background-image: linear-gradient(90deg, #69e7b7 0%, #9fffd6 100%);
            }

            .item-holder:nth-child(4) .item,
            .item-holder:nth-child(8) .item {
                background-color: #fdf5d2;
                background-image: linear-gradient(90deg, #febea7 0%, #fdf5d2 100%);
            }

            .item-holder:nth-child(5) .item {
                background-color: #ff8e80;
                background-image: linear-gradient(90deg, #fd556b 0%, #ff8e80 100%);
            }

            .item-holder:nth-child(6) .item {
                background-color: #ffd054;
                background-image: linear-gradient(90deg, #f89e27 0%, #ffd054 100%);
            }

        }
    }
}



.accordion {
    max-width: 1000px;
    margin: auto;
    display: flex;
    justify-content: center;
    position: sticky;
    flex-wrap: wrap;
    top: 24px;
    direction: rtl;

    .sidebar {
        width: 180px;
        box-shadow: -5px 8px 10px rgba(0, 0, 0, 0.2);
        height: fit-content;
        padding: 10px;

        .item-holder .item {
            width: 100%;
            border-radius: 10px 5px 15px 15px;
            box-shadow: 0px 0px 10px #ddd;
            z-index: 1;
            padding: 5px;
            cursor: pointer;
            margin: 5px;
            // background: #ffefcc;
            text-align: right;
            color: #473526;
            font-weight: bold;
            padding: 10px;
        }
    }

    .Accordion {
        .AccordionSummary {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            width: 100%;
            font-weight: bold;
            border-radius: 0px 5px 15px 15px;
            box-shadow: -5px 8px 10px rgba(0, 0, 0, 0.2);
            padding: 12px 24px;
            transition: transform 0.2s ease-in-out;
            border: 4px red dotted;
            cursor: pointer;
        }
    }

    position: relative;

    .show .AccordionDetails {
        display: none;
    }

    .accordion-cashier {
        box-shadow: 0 0 20px inset blue;
        border-radius: 20px;
        height: 100vh;
        overflow: scroll;
        padding: 13px;
        flex: 1;
    }
}