.MuiList-root {
  box-shadow: -2px 2px 83px rgba(0, 0, 0, 0.2);

  h1 {
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
  }
}

.MuiIconButton-label {
  padding: 10px;    
  height: 107px;
  background: azure;
  width: 112px;
  border-radius: 21px;
}

.stack-d {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  box-shadow: -2px 2px 83px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;

  background: #ffc7a3;
  background: linear-gradient(90deg, #d57589 0%, #ffc7a3 100%);
  border-radius: min(5vw, 50px);
}

.tab {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: -2px 2px 83px rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  gap: 10px;
  background: linear-gradient(180deg, #ffffff 0%, #64f4b2 100%);
  margin-bottom: 16px;

  h1 {
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
  }

  .tab-item {
    display: flex;
    align-items: center;
    background: #e7f3ff;
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;
    span{
      margin: 1px;
      padding: 1px;
      border-radius: 20px;
      background: #0f4c81;
      color: white;
    }

    &.active {
      background: #1879f3;
      color: white;
    }
    &:hover {
      background: #1879f3;
      color: white;
    }
    
  }
}

.notification {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  .accept {
    background: linear-gradient(180deg, #58ea95 0%, #6bf9c2 100%);
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    color: black;
    cursor: pointer;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);
  }

  .delete {
    background: linear-gradient(180deg, #ea585f 0%, #f96b7b 100%);
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    color: black;
    cursor: pointer;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.2);
  }
}