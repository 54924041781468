.casher {
    display: flex;


    .casher__header {
        width: 405px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.238);

        >button {
            width: 100%;
            background: white;
            color: black;
            border: 0;
            border-radius: 5px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);


            .item {

                display: flex;
                justify-content: space-between;
                align-items: center;

                .invoice__total {
                    font-size: 20px;
                    font-weight: 600;

                    span {
                        padding: 0 10px;
                    }
                }
            }

        }

        .cartbxs {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            .cartbx {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                flex-grow: 1;
                background: white;
                padding: 1px;
                border-radius: 10px;
                position: relative;
                transition: all 0.3s ease-in-out;
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

                input,.qry {
                    display: none;
                }

                &:hover input, &:hover .qry {
                    display: flex;
                }
                input {
                    &:focus{
                        display: flex;
                    }
                }

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 5px;
                }

                .name {
                    display: flex;
                    flex-wrap: wrap;
                }


                .quantity {
                    background: rgba(231, 218, 255, 0.852);
                    color: rgb(71, 1, 221);
                    padding: 1px;
                    border-radius: 5px;
                    font-size: 12px;
                }
                .price {
                    background: rgba(255, 218, 218, 0.852);
                    color: red;
                    padding: 1px;
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: bolder;
                }

                .total {
                    background: #fdd000;
                }

                .cx {
                    .info {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;

                        .info__item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                            font-size: 12px;
                            color: #000;
                            background: #fff;
                            padding: 1px;
                            border-radius: 5px;
                            margin: 0 5px;
                        }
                    }

                    input {
                        left: 0;
                        width: 100%;
                        height: 14px;
                        margin: 0;
                        border: 0;
                        border-radius: 5px;
                        padding: 5px;
                        box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;

                        &:focus {
                            outline: none;
                        }

                    }


                }


            }

        }
    }

    .casher__left {
        position: relative;
        height: 98vh;
        background-color: #ddd;
        padding: 5px;
        box-sizing: border-box;
        overflow-y: scroll;

        .btn-remove, .discountByCashier button {
            right: 8px;
            color: red;
            padding: 5px 10px;
            border-radius: 8px;
            border: 0;
            font-size: 24px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
            background: rgba(255, 218, 218, 0.852);
        }

        .invoice__pay {
            position: relative;
            color: blue;
            padding: 5px 10px;
            border-radius: 8px;
            border: 0;
            font-size: 24px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
            // background: rgba(0, 0, 255, 0.852);
            background: rgba(231, 228, 255, 0.852);
            cursor: pointer;
            &:hover {
                background: rgba(255, 196, 196, 0.952);
            }

            .total {
                position: absolute;
                top: 0;
                left: 0;
                background: red;
                color: rgb(251, 251, 251);
                padding: 1px;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 900;
                text-decoration: line-through;
            }
        }

        .cuscash {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

        }

        select {
            border-radius: 5px;
            padding: 5px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;

            &:focus {
                outline: none;
            }

            option {
                background: #fff;
            }
        }

        .type_radio {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .mark {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                background: rgba(0, 128, 0, 0.098);

                input {
                    display: none;

                    &:checked+label {
                        background: rgba(172, 255, 47, 0.322);
                        border-left: 8px solid green;
                        box-shadow: 0 0 5px green, 0 0 110px green, 0 0 15px green inset;
                    }
                }

                label {
                    border-radius: 10px;
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
                    font-size: 14px;
                    cursor: pointer;
                    border-left: 8px solid white;
                    background: #eee;
                    padding: 5px 10px;
                    border-radius: 5px;

                    &:hover {
                        background: rgba(255, 166, 0, 0.102);
                    }
                }

                cursor: pointer;
            }
        }

        .customer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;


            .list {
                width: 100%;
                z-index: 22;

                input {
                    width: 110px;
                    height: 25px;
                    border: 0;
                    border-radius: 5px;
                    padding: 0 10px;
                    box-sizing: border-box;
                }

                .drop {
                    cursor: pointer;
                    position: relative;

                    h3 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
                        border-radius: 5px;
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 10px;
                        background: #fff;
                    }

                    .drop__content {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                    }

                    .drop__item {
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        width: 100%;
                        background: #fff;
                        box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
                        border-bottom: 1px solid #ddd;
                        cursor: pointer;

                        p {
                            background: rgb(255, 167, 119);
                            padding: 5px;
                            border-radius: 5px;
                        }

                        .number {
                            background: #f77;
                            padding: 5px;
                            border-radius: 5px;
                        }

                        &:hover {
                            background: #eee;
                        }
                    }
                }
            }


            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 25px;
                padding: 4px;
                background: #F77;
                border-radius: 5px;
                color: black;
                font-size: 16px;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    background: #eee;
                }
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border: 1px solid #e0e0e0;

            thead {
                tr {
                    th {
                        border-bottom: 1px solid #e0e0e0;
                        text-align: left;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border-bottom: 1px solid #e0e0e0;
                        text-align: left;
                    }
                }
            }
        }

        input {
            width: 100%;
            height: 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 0 10px;
            box-sizing: border-box;

            &:focus {
                outline: none;
                border: 1px solid #f77;
            }
        }

        .qry {
            position: absolute;

            &:hover .keyword-toutch {
                display: flex;
            }

            span {
                margin: 0;
                background: #ff0000;
                color: #fff;
                padding: 0 5px;
                border-radius: 5px;
                font-size: 22px;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .btn {
            background-color: rgba(120, 244, 54, 0.2549019608);
            color: #005d30;
            border: none;
            border-radius: 12px;
            padding: 0 5px;
            text-decoration: none;
            display: inline-block;
            font-size: 28px;
            font-weight: 400;
            margin: 0px 4px;
            cursor: pointer;
        }

        .btn-danger {
            background-color: #f4433641;
            color: #f44336;
        }

        .btn-success {
            background-color: #78f43641;
            color: #005d30;
        }

        .btn-warning {
            background-color: #ff980041;
            color: #7d4b00;
        }

        .invoice {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 4px;
            padding: 10px;
            background: rgb(255, 255, 255);
            width: 100%;
            border-radius: 14px;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            margin: 10px auto;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

            .item {

                display: flex;
                justify-content: space-between;
                align-items: center;

                .invoice__total {
                    font-size: 20px;
                    font-weight: 600;

                    span {
                        padding: 0 10px;
                    }
                }
            }

        }


    }

    .casher__right {
        flex: 1;
        overflow: scroll;
        height: 100vh;

        .cards-cashier {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;


            .card {
                position: relative;
                width: 100px;
                border-radius: 4px;
                font-weight: bolder;
                cursor: pointer;

                &:hover {
                    background: #ff7429;
                }
                .card__price {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #ff7429;
                    color: black;
                    padding: 0 5px;
                    border-radius: 0 0 0 5px;
                    font-size: 12px;
                }

                img {
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 1px 55px rgb(0, 3, 90);
                    border-radius: 10px;
                    width: 100px;
                }
            }
        }
    }
}

.keyword-toutch {
    display: none;
    position: absolute;
    bottom: -230px;
    right: 0;
    background: #000;
    z-index: 999;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #ddd;
    width: 200px;

    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%),
        1px 25px 35px rgb(16 16 16 / 50%), 1px 30px 60px rgb(16 16 16 / 50%);

    button {
        width: 54px;
        height: 54px;
        margin: 2px;
        background: rgb(4, 141, 148);
        border: none;
        border-radius: 50%;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background: rgb(160, 165, 0);
        }

    }
}



.payCashier{
    position: relative;
    color: blue;
    padding: 5px 10px;
    border-radius: 8px;
    border: 0;
    font-size: 24px;
    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;
    input{
        border: 0;
        outline: none;
        font-size: 24px;
        border-radius: 14px;
        font-weight: 600;
        color: #000;
        box-shadow: 1px 1px 51px #919191, 1px 2px 15px #919191;
    }
 
    .bank span, .cash span{
        font-size: 20px;
        font-weight: 600;
        color: #000;
        // style as button 
        padding: 0 5px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 5px;
        background: #fa7b7b;
        color: #8d0032;
        box-shadow: -35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset 8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
        &:hover {
            background: rgb(160, 165, 0);
        }
    }
    button {
        width: 200px;
        height: 54px;
        margin: 2px;
        background: rgb(4, 141, 148);
        border: none;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        box-shadow: -35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset 8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
        &:hover {
            background: rgb(160, 165, 0);
        }
        .total {
            position: absolute;
            top: 0;
            left: 0;
            background: #ff7429;
            color: rgb(0, 0, 0);
            padding: 1px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 900;
            text-decoration: line-through;
        } 
    }

    
}
