$color_1: white;

.form {
	form {
		display: flex;
		flex-direction: column;
		width: 355px;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		.error {
			color: red;
			background:  #ff00001c;
			border-radius: 11px;
			padding: 11px; 
			font-size: 20px;
			font-weight: 800;
		}
		input {
			height: 44px;
			width: 311px;
			margin: 11px;
			border-radius: 11px;
			border: 0;
			border-bottom: 4px solid;
		}
	}
	button {
		border: 0;
		background: black;
		color: $color_1;
		padding: 11px 66px;
		cursor: pointer;
		&:hover {
			border: 0;
			background: rgba(0, 0, 0, 0.616);
			color: $color_1;
			padding: 11px 66px;
		}
	}
}
.sing {
	margin: 0 auto;
	.singup {
		background: skyblue;
		border-radius: 11px;
		text-align: center;
		display: flex;
		a {
			background: black;
			color: $color_1;
			padding: 11px;
			border-radius: 12px;
			margin: 2px;
			text-decoration: none;
		}
	}
}

