
.phome{ 
    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #262226;  
        z-index: -1;
    }
}
.text { 
    top: 50%; 
    right: 50%; 
    text-transform: uppercase;
    font-family: verdana;
    font-size: 6em;
    font-weight: 700;
    color: #f5f5f5;
    text-shadow: 1px 1px 1px #919191,
        1px 2px 1px #919191,
        1px 3px 1px #919191,
        1px 4px 1px #919191,
        1px 5px 1px #919191,
        1px 6px 1px #919191,
        1px 7px 1px #919191,
        1px 8px 1px #919191,
        1px 9px 1px #919191,
        1px 10px 1px #919191,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
}


.cards{
    display: flex;
    align-content: space-between;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}
.card{
    position: relative;
    margin: 2px;
    width: 200px;
    border-radius: 22px;
    transition: all 0.5s ease;
    box-shadow:  1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);
    &:hover{
        box-shadow: 0 0 10px #000;
    }
}
.cards img{
    width: 200px;
    height: 200px;
    border-radius: 22px;
    box-shadow: 0 0 140px, 0 0 13px brown;
}
.cards button{
    padding: 12px;
    border: 0;
    color: #fff;
    border-radius: 4px ;
    background-color: #000;
}
.card .qry{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: 0 0 0 22px;
    background-color: #000;
    background: azure;
    top: 0;
    right: 0;
    box-shadow: 0 0 43px;
    padding: 5px;
}

.cards .card__price{
    background: #000;
    position: absolute;
    padding: 5px;
    left: 0;
    color: white;
    border-radius: 0 0 15px 0;
}
.cards .name{
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
    color: white;
    border-radius: 0 0 0 15px;
    background: #000;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    font-weight: 700;
    text-transform: uppercase;
}
.cover{
    width: 97vw;
    height: 221px; 
    background-image: linear-gradient(180deg,#7014e8 30.12%,#0e5ecf 192.47%);
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2%;
    box-shadow: 0 0 5px;
}

.catg{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;

    >p{
        img{
            width: 55px;
            height: 55px;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;
        padding: 6px;
        border-radius: 22px 11px / 211px 22px;
        color: #fff;
        cursor: pointer;
        background: #007bff;
        position: relative;
        transition: all 0.2s ease-in-out;
        &:hover{
            transform: scale(1.1);
        }
        span{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%; 
            background: rgba(0, 123, 255, 0.8);
            color: #fff;
            padding: 2px 8px;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
        }
    }
    
}