 
.Sing_in_up{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	.error {
		color: red;
	  }
	  button{
		background: #4800d8;
		color: white;
		border: none;
		border-radius: 0.5rem;
		font-size: 1.2rem;
		margin-top: 1rem;
		cursor: pointer;
		padding: 0.5rem 1rem;
	  margin: 0.5rem;
	  
	  }
	  
	  form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 2rem;
	  
		input {
		  width: 20rem;
		  height: 2rem;
		  padding: 0.5rem;
		  margin-bottom: 1rem;
		  border: none;
		  border-radius: 0.5rem;
		  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
		  font-size: 1rem;
		}
	  
		button {
		  width: 10rem;
		  height: 2.5rem;
		  background-color: #00b4d8;
		  color: white;
		  border: none;
		  border-radius: 0.5rem;
		  font-size: 1.2rem;
		  margin-top: 1rem;
		  cursor: pointer;
		}
	  
		button[type='button'] {
		  background-color: #e63946;
		}
	  }
	  
	  
}