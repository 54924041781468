.invoice {
    width: 302.36px;
    &__body {

        &__left {

            h1 {
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            p {
                font-size: 14px;
                font-weight: 700;
                border-bottom: 1px solid darkgreen;
            }

            table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 1rem;

                thead {
                    tr {
                        th {
                            border: 1px solid #000;
                            font-size: 13px;
                            font-weight: 600;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border: 1px solid #000;
                            font-size: 14px;
                            font-weight: 600;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }

}


@media print {
   
header, footer, aside, nav, form, iframe, .menu, .hero, .adslot,.casher__header,.casher__right,.print {
    display: none;
  }
    .invoice {
        width: 302.36px;
        &__body {
    
            &__left {
    
                h1 {
                    font-size: 1rem;
                    font-weight: 600;
                    margin-bottom: 1rem;
                }
    
                p {
                    font-size: 14px;
                    font-weight: 700;
                }
    
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 1rem;
    
                    thead {
                        tr {
                            th {
                                border: 1px solid #000;
                                font-size: 13px;
                                font-weight: 600;
                            }
                        }
                    }
    
                    tbody {
                        tr {
                            td {
                                border: 1px solid #000;
                                font-size: 14px;
                                font-weight: 600;
                                word-break: break-all;
                            }
                        }
                    }
                }
            }
        }
    
    }
}
