.Logs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    .all {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        background: #232123;
        border-radius: 50%;
        border: 5px solid;
        /* specify the width of the border */
        border-image: linear-gradient(to right, #000000, #ffffff) 1;
        /* specify the gradient direction, start and end color, and the number of slices */
        border-image-slice: 1;
        /* specify the number of slices */
        box-shadow: 0 0 12px #070a9f inset, 0 0 22px #000000, 0 0 41px;
        color: #fff;
    }

    .Log {
        position: relative;
        width: 100px;
        height: 100px;
        cursor: pointer;
        background: #232123;
        border-radius: 50%;
        border: 5px solid;
        transition: all 0.3s ease-in-out;
        border-image: linear-gradient(to right, pink, #ff8b00) 1;
        /* specify the gradient direction, start and end color, and the number of slices */
        border-image-slice: 1;

        /* specify the number of slices */
    &:hover {
        border-image: linear-gradient(to right, #000000, #ffffff) 1;
        box-shadow: 0 0 12px #070a9f inset, 0 0 22px #000000, 0 0 41px;
    }

        img {
            // style image like instag
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
            border: 5px solid;
            /* specify the width of the border */
            border-image: linear-gradient(to right, #000000, #ffffff) 1;
            /* specify the gradient direction, start and end color, and the number of slices */
            border-image-slice: 1;
            /* specify the number of slices */
            box-shadow: 0 0 43px;
        }

        .role {
            position: absolute;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            padding: 3px;
            border-radius: 5px;
        }

        h3 {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            padding: 3px;
            border-radius: 5px;
            font-size: 12px;
        }
    }
}