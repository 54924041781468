.editCategory{
    display: flex;
    justify-content: space-between;
    .listProddct{
        form{
            

            img{
                width: 100px;
                height: 100px;
            }
           
        #im {
            display: flex;
            align-content: center;
            justify-content: center;
            gap: 10px;
            position: relative;
            border: 2px dashed #000;

            img {
                width: 200px;
                height: 200px;
                object-fit: cover;
                border-radius: 4px;
            }

            input {
                width: 100%;
                box-shadow: 0 0 103px blue, 0 0 32px blue inset;
                height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;


            }
        }

            input{
                width: 200px;
                margin: 0 10px;
            }
            button{
                margin: 0 10px;
            }
        }
    }
}
