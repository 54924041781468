.tables0 {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(8px);
	background-color: rgba(255, 255, 255, 1);
	border-radius: 26px;
	box-shadow: -35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset 8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px #fff;
	display: block;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(8px);
	background-color: rgba(255, 255, 255, 1);
	border-radius: 26px;
	box-shadow: -35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset 8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px #fff;
	.table {
		background-color: #f2f2f2;
		padding: 2px;
		border-radius: 10px;
		box-shadow: 2px 2px 5px #888;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10px;
		cursor: pointer;
		display: block;
		min-width: 200px;
		margin: 10px;
		padding: 10px;
		border: 1px solid #000;
		border-radius: 10px;
		box-shadow: 2px 2px 5px #888;
		cursor: pointer;
		background-color: #f2f2f2;
		&:hover {
			background-color: #ccc;
			background-color: #ccc;
		}
		.number {
			font-weight: bold;
			font-size: 20px;
		}
		.tname {
			font-size: 18px;
		}
		& ~ .Available {
			color: green;
			background: rgba(0, 128, 0, 0.253);
		}
		& ~ .Busy {
			color: #fff;
			background: #ff1d58;
		}
		& ~ .Reserved {
			color: blue;
			background: rgba(0, 0, 255, 0.253);
		}
		.btn {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: inline-block;
			margin-right: 10px;
			cursor: pointer;
			&.btn-success {
				background-color: green;
				color: #fff;
			}
			&.btn-danger {
				background-color: red;
				color: #fff;
			}
			&:hover {
				background-color: #ccc;
			}
		}
	}
}

