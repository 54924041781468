.single {
    max-width: 1200px;
    margin: 20px auto;
    padding: 53px 15px;

    .single__card {
        display: flex;

        @media (max-width: 640px) {
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 330px;
            height: 330px;
            border-radius: 10px;
            box-shadow: 0 0 233px blue;
        }

      .txt {
        margin-left: 20px;
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a{
          text-decoration: none;
          color: #000;
        }
      }

        button {
            margin-top: 10px;
            padding: 10px 20px;
            border-radius: 5px;
            border: none;
            background-color: #f5f5f5;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                background-color: #e5e5e5;
            }
        }
    }

}