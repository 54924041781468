.kitchen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .table {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        width: 221px;
        cursor: pointer;
        background-color: #f2f2f2;
        padding: 2px;
        border-radius: 10px;
        box-shadow: 2px 2px 5px #888888;

        &:hover {
            background-color: #ccc;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            background: #ffc107;
            color: #000;
            border-radius: 5px; 
            font-size: 12px;
            cursor: pointer;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191;
            
            &:hover {
                background-color: #ccc;
            }

        }

        .titem {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1px;
            width: 221px;
            position: relative;
            cursor: pointer;
            background-color: #f2f2f2;
            padding: 2px;
            border-radius: 10px;
            box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

            &:hover {
                background-color: #ccc;
            }

            .qry {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 10px;
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191;
                background: rgba(231, 218, 255, 0.852);
                color: rgb(71, 1, 221);
                padding: 1px;
                border-radius: 5px;
                font-size: 12px;

            }

            .note {
                font-size: 10px;
                color: rgb(71, 1, 221);
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 10px 1px #919191, 1px 18px 6px rgb(16 16 16 / 40%), 1px 22px 10px rgb(16 16 16 / 20%), 1px 25px 35px rgb(16 16 16 / 20%), 1px 30px 60px rgb(16 16 16 / 40%);

            }
            
            .modifier {
                display: flex;
                flex-wrap: wrap; 
                padding: 1px;
                border-radius: 5px;
                background: rgba(255, 255, 255, 0.5);
                box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 18px 6px rgb(16 16 16 / 5%);
                gap: 0 5px;

                &:hover {
                    background: rgba(103, 240, 181, 0.252);
                }

                .modifier__item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    background: #d84c4c73;
                    color: #000;
                    border-radius: 5px;
                    padding: 0 5px;
                    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191, 1px 18px 6px rgb(16 16 16 / 5%);
                    .price {
                        font-size: 16px;
                        font-weight: 600;
                        background: #dff2326c;
                        border-radius: 11px;
                        padding: 0 5px;
                    }
                    &:hover {
                        background: #f08585;
                    }
                }
            }

        }
    }


}