header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    border-radius: 22px;
    margin: 0 auto 22px auto;
    box-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191;

    .logo {

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        border-radius: 14px;
        background: #000;
        color: #fff;
        font-size: 22px;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.3s ease-in-out;


        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
            box-shadow: 0 0 11px black;
        }

        span {
            font-size: 22px;
            font-weight: 700; 
            margin-left: 11px;
            

        }

    }

    header ul {
        display: flex;
        align-items: center;

        a {
            padding: 4px;
            text-decoration: none;
        }

    }

    .sing {
        display: flex;
        align-items: center;
        justify-content: center;

        .lang {
            box-shadow: 0 0 111px black;
            border-radius: 4px;
        }
    }

}



.useer {
    position: fixed;
    flex-flow: column;
    z-index: 111;
    position: relative;
    padding: 0 21px;
    color: black;
    border: 0;
    border-radius: 4px;
    font-size: 22px;
    cursor: pointer;

    .avatar {
        padding: 2px;

        img {
            width: 40px;
            height: 40px;
            border: blue solid 4px;
            border-radius: 50%;
            box-shadow: 0 0 11px black;
        }

    }

    .dropmenu {
        display: none;
        flex-flow: column;
        position: absolute;
        background: #000;
        color: #fff;
        padding: 8px;
        right: 5px;
        border-radius: 11px;
        box-shadow: 0 0 11px black;
    }

    // make the dropmenu visible when the user hovers over the useer class
    &:hover .dropmenu {
        display: flex;
    }

    p {
        background: #000;
        padding: 4px;
        border-radius: 24px;
        font-size: 9px;
        font-weight: 700;
        color: white;
    }

    button {
        background: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border: 0;
        padding: 4px;
        border-radius: 4px;
        font-size: 22px;
    }
}


// .fext {
//     position: fixed;
//     bottom: 22px;
//     right: 44px;
//     z-index: 111;
//     a {
        
//         img {
//             position: absolute;
//             top: 0;
//             left: 0;
//             object-fit: cover;
//             border-radius: 24px;
    
//             width: 100px;
//             height: 100px;
//         }
     
//         .cartN {
//             position: absolute;
//             top: 29px;
//             right: -7px;
//             background: #6459d2;
//             color: #ffffff;
//             box-shadow: 0 0 17px, 0 0 27px black, 0 0 27px #493a74, 0 0 27px #6f15e8, 0 0 27px #380101, 0 0 27px #5a24e2;
//             font-size: 24px;
//             padding: 0px;
//             border-radius: 8px;
//             font-weight: bolder;
//         } 
    
//     }
    
// }

// .admin {
//     margin: 45px 0;
//     font-size: 22px;
//     bottom: 0;
//     width: 100%;
//     display: flex;
//     justify-content: center;
// }



.nav_header{
    
    .admin {
        font-size: 22px;
        bottom: 0;
        width: 100%;
        display: flex;
        gap: 5px;
        justify-content: center;
        flex-wrap: wrap;
        a{
            width: 100px;
            display: flex;
            flex-direction: column;
            padding: 4px;
            text-decoration: none;
            // background: #10d73e; red to green gradient
            // background: linear-gradient(90deg, #551212 0%, #00ff00 100% , #551212 100%, #00ff00 100%);
            color: #000;
            border-radius: 14px;
            font-size: 22px;
            font-weight: 700;
            transition: all 0.3s ease-in-out;
            box-shadow: -35px 35px 68px 0px rgb(145 192 255 / 50%), inset 8px -8px 16px 0px rgb(145 192 255 / 60%), inset 0px 11px 28px 0px rgb(255 255 255);
           img{
                width: 100%;
                height: 100px;
                object-fit: cover;
                border-radius: 50%;
                box-shadow: 0 0 101px blue;
           }
            &:hover{
                background: #c50a0a;
                color: #000;
            }
            span {
                box-shadow: -35px 35px 68px 0px rgb(145 192 255 / 50%), inset 8px -8px 16px 0px rgb(145 192 255 / 60%), inset 0px 11px 28px 0px rgb(255 255 255);
            }

        }
        .dashboard{
            background: #fa7b7b;
            color: #8d0032;
        }
        .bill{
            background: #7bfa92;
            color: #810086;
        }
        .kitchen{
            background: #7bbafa;
            color: #2f0086;
        }
        .customer{
            background: #b47bfa;
            color: #860048;
        }
        .cashier{
            background: #fa7bcb;
            color: #860048;
        }
        .waiter{
            background: #fa7b90;
            color: #860048;
        }
        .dining{
            background: #facb7b;
            color: #860048;
        }
        .table{
            background: #7bfaef;
            color: #860048;
        }
        .makeStyles-root-1{
            background: #ffffff6b;
        }
    }
}