#data-sel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;

    &:hover {
        background: #eee;
    }

    input {
        width: 170px;
        height: 40px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0 10px;
        box-sizing: border-box;
    }

    button {
        width: 100px;
        height: 40px;
        background: rgba(0, 0, 255, 0.138);
        color: blue;
        border-radius: 5px;
        padding: 0 10px;
        box-sizing: border-box;
    }
}

.data {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;

    .data-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.238);

        .h1 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #000;
        }

        .p {
            font-size: 1.5rem;
            font-weight: 600;
            color: #000;
        }
    }
}

.Sales {
    background: rgba(0, 117, 74, 0.147);

    h1 {
        color: green;
    }
}

.Profite {
    background: rgba(255, 0, 0, 0.138);

    h1 {
        color: red;
    }
}

.Amount {
    background: rgba(0, 0, 255, 0.138);

    h1 {
        color: blue;
    }
}

.Paid {
    background: rgba(0, 0, 255, 0.138);
    color: blue;
    padding: 3px;
    border-radius: 5px;
    box-shadow: 0 0 43px ;
}
.Pending{
    background: rgba(0, 117, 74, 0.147);
    color: green;
    padding: 3px;
    border-radius: 5px;
    box-shadow: 0 0 43px ;
}
.Void{
    background: rgba(255, 0, 0, 0.138);
    color: #f50057;
    padding: 3px;
    border-radius: 5px;
    box-shadow: 0 0 43px ;
}

.payment{
    background: rgb(129, 129, 255);
    color: blue;
    padding: 3px;
    margin-left: 4px;   
    border-left: 3px solid green;
    border-radius: 5px;
    box-shadow: -35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset 8px -8px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);    span{
        color: #000;
    }
}
.mark{
    display: flex;
    flex-wrap: wrap;
    margin: 14px;
    margin-top: 22px;
    align-items: center;
    justify-content: center;
    
    .box{
        width: 150px;
        height: 150px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        justify-content: center;
        img{
            position: absolute;
            padding: 6px;
            margin: 10px;
            border-radius: 11px;
            border: 5px solid rgb(255, 255, 255);
            box-shadow: 0 0 43px ;
            width: 150px;
            height: 150px;
        }
        button{
            position: absolute;
            width: 150px;
            height: 150px;
            border-radius: 11px;
            box-shadow: 0 0 43px ;
            background: rgba(0, 0, 0, 0.264);
            color: rgb(255, 202, 202);
            font-size: 1.5rem;
            font-weight: 600;
            // make as gold color with effect when hover    
            &:hover{
                background: rgba(255, 202, 0, 0.397);
                color: rgb(0, 0, 0);
            }


        }
    }
}