.dashboard {
    display: flex;
    align-content: center;
    width: 100%;

    .body {
        text-align: left;
        margin: 12px;
        font-size: 20px;
        font-weight: 600;
        background: #fff;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.238);
        width: 100%;

        h1 {
            display: flex;
            align-items: center;
            font-size: 2rem;
            font-weight: 600;
            color: #000;
        }

        p {
            font-size: 1rem;
            font-weight: 400;
            color: #000;
        }
        #data-sel {
            display: flex; 
            flex-direction: row; 
            flex-wrap: wrap;
            width: 100%;
            border-radius: 5px;
            background: #fff;
            cursor: pointer;
            &:hover {
                background: #eee;
            }
            input{
                width: 170px;
                height: 40px;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 0 10px;
                box-sizing: border-box; 
            }
            button{
                width: 100px;
                height: 40px;
                background: rgba(0, 0, 255, 0.138);
                color: blue;
                border-radius: 5px;
                padding: 0 10px;
                box-sizing: border-box; 
            }
        }
        .data{
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px;
            padding: 10px;
            border-radius: 10px; 
            .data-item{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 10px;
                padding: 10px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.238);
                .h1{
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #000;
                }
                .p  {
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #000;
                }
            }
        }
        .Sales{
            background: rgba(0, 117, 74, 0.147);
            h1{
                color: green;
            }
        }
        .Profite{
            background: rgba(255, 0, 0, 0.138);
            h1{
                color: red;
            }
        }
        .Amount{
            background: rgba(0, 0, 255, 0.138);
            h1{
                color: blue;
            }
        }

        table{
            
            img{
                width: 50px;
                height: 50px;
            }
        }

    }

}